import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ActiveProjectBeneficiariesChart = ({ activeProjectBeneficiaries }) => {
    // Convert object to an array if needed
    const dataArray = Array.isArray(activeProjectBeneficiaries)
    ? activeProjectBeneficiaries
    : Object.values(activeProjectBeneficiaries); // Converts object to an array
  
    if (!Array.isArray(dataArray) || dataArray.length === 0) {
        return <p>No data available</p>;
    }
    
    // Extract all unique project names from all entries
    const projectNames = [...new Set(dataArray.flatMap(obj => Object.keys(obj).filter(key => key !== "name")))];
    
    // Calculate total beneficiaries for each project
    const totalBeneficiariesByProject = projectNames.reduce((totals, projectName) => {
      totals[projectName] = dataArray.reduce((sum, obj) => sum + (obj[projectName] || 0), 0);
      return totals;
    }, {});
  
    return (
      <div className="card text-center mt-5">
                  <div className="card-body">
                    <h3 className="text-start p-1 mt-2 mb-2">Beneficiary Summary</h3>
                    {/* <table className="table table-sm table-striped table-hover mt-3">
                      <thead>
                        <tr>
                          <th scope="col">Coordinator ID</th>
                          <th scope="col">Coordinator Name</th>
                          <th scope="col">Beneficiary Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(beneficiarySummary).map(([id, member]) => (
                          <tr key={id}>
                            <td>{id}</td>
                            <td>{member.name}</td>
                            <td>{member.count}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="2">Total Beneficiaries</th>
                          <th>{totalBeneficiaries.toLocaleString()}</th>
                        </tr>
                      </tfoot>
                    </table> */}
                     <ResponsiveContainer width="100%" height={400}>
                      <BarChart data={dataArray} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" angle={-70} textAnchor="end" height={120} />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign="top" wrapperStyle={{ marginBottom: 20 }} />

                        {/* Dynamically generate bars for all project names */}
                        {projectNames.map((projectName, index) => (
                          <Bar key={projectName} dataKey={projectName} stackId="a" fill={["#8884d8", "#82ca9d", "#ffc658", "#d88484", "#84d8c4"][index % 5]} />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>              
                  </div>
                  <div className="card-footer text-body-secondary">
                    {Object.entries(totalBeneficiariesByProject).map(([project, total], index, array) => (
                      <span key={project}>
                        <strong>{project}: </strong> 
                        <span class="badge rounded-pill text-bg-primary">{total.toLocaleString()}</span>
                        {index < array.length - 1 && " | "}
                      </span>
                    ))}
                  </div>
                </div>
    );
  };

  export default ActiveProjectBeneficiariesChart;