import './App.css';
import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import Login from './components/Login';
import Home from './components/Home';
import Beneficiaries from './components/Beneficiaries';
import AddBeneficiary from './components/AddBeneficiary';
import EditBeneficiary from './components/EditBeneficiary';
import BeneficiariesReport from './components/BeneficiariesReport';
import Members from './components/Members';
import MembershipForm from './components/MembershipForm';
import EditMember from './components/EditMember';
import Projects from './components/Projects';
import AddProject from './components/AddProject';
import EditProject from './components/EditProject';
import Donations from './components/Donations';
import AddDonation from './components/AddDonation';
import EditDonation from './components/EditDonation';
import Reports from './components/Reports';
import ProjectBeneficiaries from './components/ProjectBeneficiaries';
import UnassignedBeneficiaries from './components/UnassignedBeneficiaries';

import Notifications from './components/Notifications';
import AddNotification from './components/AddNotification';
import EditNotification from './components/EditNotification';

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/beneficiaries/all" element={<Beneficiaries />} />
          <Route path="/beneficiaries/unassigned" element={<UnassignedBeneficiaries />} />
          <Route path="/beneficiaries/add" element={<AddBeneficiary />} />
          <Route path="/beneficiaries/edit" element={<EditBeneficiary />} />
          <Route path="/report/beneficiaries/all" element={<BeneficiariesReport />} />
          <Route path="/members" element={<Members />} />
          <Route path="/members/edit" element={<EditMember />} />
          <Route path="/membershipform" element={<MembershipForm />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/add" element={<AddProject />} />
          <Route path="/projects/edit" element={<EditProject />} />
          <Route path="/donations" element={<Donations />} />
          <Route path="/donations/add" element={<AddDonation />} />
          <Route path="/donations/edit" element={<EditDonation />} />
          <Route path="/reports" element={<Reports />} />

          <Route path="/projectbeneficiaries" element={<ProjectBeneficiaries />} />

          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notifications/add" element={<AddNotification />} />
          <Route path="/notifications/edit" element={<EditNotification />} />

        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;