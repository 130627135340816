import React, { useEffect, useState } from "react";
import axios from "axios";
// import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";

const BeneficiariesReport = ({ coordinatorId }) => {
  useRequireAuth();
  const { userData } = useUser();
  const navigate = useNavigate();

  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    const fetchBeneficiaries = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${server.apiUrl}/getBeneficiaries`, {
          coordinatorId
        });

        if (response.data.success) {
          if (response.data.userBeneficiaries.length)
            setBeneficiaries(response.data.userBeneficiaries);
          else {
            setAlertType("warning");
            setAlertMessage("No beneficiaries found.");
          }
        } else {
          setAlertType("danger");
          setAlertMessage("Beneficiaries could not be retrieved.");
        }
      } catch (error) {
        setAlertType("danger");
        setAlertMessage("Beneficiaries could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchBeneficiaries();
  }, [userData]);

  const handleBack = () => {
    navigate("/");
  };
  // const handleSaveAsPDF = () => {
  //   const doc = new jsPDF();
  //   doc.text("Beneficiaries Report", 10, 10);
  //   doc.autoTable({ html: '#beneficiariesTable' });
  //   doc.save("beneficiaries_report.pdf");
  // };

  return (
    isLoading ? (
      <Spinner />
    ) : (
      <>
        <div className="row border border-dark border-start-0 border-end-0">
          <div className="col-md-6">
            <img
              src="/logos/logo-login.png"
              //className="img-fluid mx-auto d-block"
              style={{
                width: "250px"
              }}
              alt="Rearing Youth"
            />
          </div>
          <div className="col-md-6 text-end">
            <h2 className="mt-2">BENEFICIARY REPORT</h2>
            {coordinatorId !== "M00001" && <h4 className="mt-4">Coordinator: {beneficiaries.length > 0 && beneficiaries[0].coordinatorName}</h4>}
            {/* <button className="btn btn-primary" onClick={handleSaveAsPDF}>Save as PDF</button> */}
          </div>
        </div>
        {alertMessage ? (
          <Alert type={alertType} message={alertMessage} />
        ) : (
          <table id="beneficiariesTable" className="table table-striped table-hover mt-4">
            <thead>
              <tr>
                <th className="text-center">Sr.</th>
                <th className="text-center">ID</th>
                <th className="text-center">CNIC</th>
                <th>Name</th>
                <th className="text-center">Occupation</th>
                <th className="text-center">Contact No.</th>
                <th>Notes</th>

                {/* Columns:
                  Beneficiary Id,
                  Name,
                  contact no,
                  created by,
                  coordinator,
                  Notes (from project beneficiareis) and
                  Status (from Project beneficiaries) */}
              </tr>
            </thead>
            <tbody>
              {beneficiaries.map((beneficiary, index) => (
                <tr key={beneficiary.formId}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{beneficiary.id}</td>
                  <td className="text-center">{beneficiary.cnic}</td>
                  <td>{beneficiary.name}</td>
                  <td className="text-center">{beneficiary.occupation}</td>
                  <td className="text-center">{beneficiary.contactNo}</td>
                  <td>{beneficiary.notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <button
          type="button"
          className="btn btn-outline-danger mt-2"
          onClick={handleBack}
        >
          <i className="bi bi-arrow-left me-2"></i>Back
        </button>
      </>
    )
  );
};

export default BeneficiariesReport;
