import React from "react";
import { NavLink } from "react-router-dom";
import { useUser } from '../context/UserContext';


const NavBar = () => {
  const { userData, logoutUser } = useUser();

  const handleLogout = () => {
    // Call logoutUser function from UserContext
    logoutUser();
    // Optionally, perform any additional actions upon logout, such as redirecting to the login page
    // history.push('/login'); // Assuming you're using react-router-dom
  };
  // const handleLogout = async () => {
  //   try {
  //     await signOut(auth);
  //     // Clear the user data from UserContext after successful sign-out
  //     //  setUser(null);

  //     // Clear user data from local storage after successful sign-out
  //     localStorage.removeItem("user");
  //   } catch (error) {
  //     console.error("Error during logout:", error);
  //   }
  // };

  return (
    <nav className="navbar navbar-expand-lg bg-light border-bottom border-secondary bg-gradient">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img
            src="/logos/logo-mini.png"
            style={{
              height: "40px",
            }}
            alt="Rearing Youth"
          />
          <img
            src="/logos/logo-nav.png"
            style={{
              height: "30px",
            }}
            alt="Rearing Youth"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            {userData && userData.role === "Admin" &&
              <>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Beneficiaries
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <NavLink className="nav-link" to="/beneficiaries/all" activeClassName="active">
                        All
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="nav-link" to="/beneficiaries/unassigned" activeClassName="active">
                        Unassigned
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <NavLink className="nav-link" to="/members" activeClassName="active">
                  Members
                </NavLink>
                <NavLink className="nav-link" to="/projects" activeClassName="active">
                  Projects
                </NavLink>
                <NavLink className="nav-link" to="/notifications" activeClassName="active">
                  Notifications
                </NavLink>
                <NavLink className="nav-link" to="/reports" activeClassName="active">
                  Reports
                </NavLink>
              </>
            }
            {userData && userData.role !== "Admin" && userData.role !== "Patron" &&
              <>
                <NavLink className="nav-link" to="/beneficiaries/all" activeClassName="active">
                  Beneficiaries
                </NavLink>
              </>
            }
            {userData &&
              <>
                <NavLink className="nav-link" to="/donations" activeClassName="active">
                  Donations
                </NavLink>
              </>
            }
            {/* <NavLink className="nav-link" to="/income" activeClassName="active">
              Income
            </NavLink>
            <NavLink
              className="nav-link"
              to="/expenses"
              activeClassName="active"
            >
              Expenses
            </NavLink>
            <NavLink
              className="nav-link"
              to="/reports"
              activeClassName="active"
              onClick={onReportsLinkClick}
            >
              Reports
            </NavLink> */}
          </div>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-outline-danger mx-2"
            onClick={handleLogout}
          >
            <i className="bi bi-box-arrow-right me-2"></i>Signout
          </button>
        </div>
      </div >
    </nav >
  );
};

export default NavBar;
