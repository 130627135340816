import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
import MaskedInput from "react-text-mask";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const AddBeneficiary = () => {
  useRequireAuth();
  const { userData } = useUser();

  const initialState = {
    createdDate: new Date().toISOString().split("T")[0],
    id: "",
    name: "",
    cnic: "",
    monthlyIncome: 0,
    contactNo: "",
    address: "",
    city: "",
    occupation: "",
    education: "",
    incomeSource: "",
    residence: "",
    dependentsCount: 0,
    notes: "",
    isVerified: false,
    coordinator: userData.id
  };

  const [beneficiaryData, setBeneficiaryData] = useState(initialState);
  const [formReset, setFormReset] = useState(false);

  const { name, cnic, monthlyIncome, contactNo, address, city, occupation, education, incomeSource, residence, dependentsCount, notes } = beneficiaryData;

  const navigate = useNavigate();

  // State variables to manage the alert
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBeneficiaryData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const generateBeneficiaryId = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server.apiUrl}/getBeneficiaryCount`);
        const totalCount = response.data.beneficiaryCount;
        const newId = `B${String(totalCount + 1).padStart(5, '0')}`;
        setBeneficiaryData((prevData) => ({
          ...prevData,
          id: newId,
        }));
      } catch (error) {
        console.error("Error fetching total count of beneficiaries:", error);
      }
      setIsLoading(false);
    };
    generateBeneficiaryId();
  }, [formReset]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${server.apiUrl}/addBeneficiary`, {
        beneficiaryData,
        createdBy: userData.id
      });
  
      if (response.status === 201) {
        setAlertType("success");
        setAlertMessage(response.data.message);
        setBeneficiaryData(initialState);
        setFormReset(!formReset);
      } else {
        // Handles unexpected non-error responses
        setAlertType("danger");
        setAlertMessage(`Unexpected response: ${response.data.error || "Please try again."}`);
      }
    } catch (error) {
      setAlertType("danger");
  
      if (error.response) {
        // Backend responded with an error status (e.g., 400, 500)
        setAlertMessage(`Failed to add beneficiary: ${error.response.data.error || "Unknown error from server"}`);
      } else if (error.request) {
        // Request was made, but no response received
        setAlertMessage("Failed to add beneficiary: No response from server.");
      } else {
        // Something went wrong while setting up the request
        setAlertMessage(`Failed to add beneficiary: ${error.message}`);
      }
    }
    setIsLoading(false);
  };
  
  const handleBack = () => {
    navigate(`/beneficiaries`);
  };

  return (
    <div>
      <NavBar />
      <div className="container mt-4">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <h2>
              Add Beneficiary
            </h2>
            <form onSubmit={handleSubmit} className="mt-4">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="contactNo" className="form-label">
                      Contact No.
                    </label>
                    <MaskedInput
                      mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                      className="form-control"
                      id="contactNo"
                      name="contactNo"
                      value={contactNo}
                      onChange={handleChange}
                      placeholder="0000-0000000"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="cnic" className="form-label">
                      CNIC
                    </label>
                    <MaskedInput
                      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/]}
                      className="form-control"
                      id="cnic"
                      name="cnic"
                      value={cnic}
                      onChange={handleChange}
                      placeholder="00000-0000000-0"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="occupation" className="form-label">
                      Occupation
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="occupation"
                      name="occupation"
                      value={occupation}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="education" className="form-label">
                      Education
                    </label>
                    <select
                      className="form-select"
                      id="education"
                      name="education"
                      value={education}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        Select Education
                      </option>
                      <option key="Uneducated" value="Uneducated">
                        Uneducated
                      </option>
                      <option key="Primary" value="Primary">
                        Primary
                      </option>
                      <option key="Middle" value="Middle">
                        Middle
                      </option>
                      <option key="Matric" value="Matric">
                        Matric
                      </option>
                      <option key="Higher" value="Higher">
                        Higher
                      </option>
                      <option key="Other" value="Other">
                        Other
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="dependentsCount" className="form-label">
                      No. of Dependents
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="dependentsCount"
                      name="dependentsCount"
                      value={dependentsCount}
                      onChange={handleChange}
                      min="0"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="monthlyIncome" className="form-label">
                      Monthly Income
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="monthlyIncome"
                      name="monthlyIncome"
                      value={monthlyIncome}
                      onChange={handleChange}
                      min="0"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="incomeSource" className="form-label">
                      Income Source
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="incomeSource"
                      name="incomeSource"
                      value={incomeSource}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="residence" className="form-label">
                      Residence
                    </label>
                    <select
                      className="form-select"
                      id="residence"
                      name="residence"
                      value={residence}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        Select Residence
                      </option>
                      <option key="owned" value="owned">
                        Owned
                      </option>
                      <option key="rented" value="rented">
                        Rented
                      </option>
                      <option key="other" value="other">
                        Other
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      name="address"
                      value={address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      name="city"
                      value={city}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="notes" className="form-label">
                      Notes
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      id="notes"
                      name="notes"
                      value={notes}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn btn-outline-success me-2"
                  >
                    <i className="bi bi-save me-2"></i>Add
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={handleBack}
                  >
                    <i className="bi bi-x-square me-2"></i>Back
                  </button>
                </div>
              </div>
            </form>
            {alertMessage && <Alert type={alertType} message={alertMessage} />}
          </>
        )
        }
      </div >
    </div >
  );
};

export default AddBeneficiary;