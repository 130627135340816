import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
import Error from "./Error";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';
import DeleteBeneficiary from "./DeleteBeneficiary";

const ProjectBeneficiaries = () => {
  const [projectBeneficiaries, setProjectBeneficiaries] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [showModal, setShowModal] = useState(null);
  useRequireAuth();
  const { userData } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const initialState = {
    id: "",
    name: "",
    type: "",
    status: "",
    manager: "",
    budget: 0,
    startDate: "",
    endDate: "",
    notes: "",
  };

  const [projectData, setProjectData] = useState(initialState);

  const { name, type, status, manager, budget, startDate, endDate, notes } = projectData;

  useEffect(() => {
    const fetchProjectBeneficiaries = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get("id");

        const response = await axios.post(`${server.apiUrl}/getProjectBeneficiaries`, {
          userId: userData.id,
          userRole: userData.role,
          projectId: id
        });

        if (response.data.success) {
          setProjectData({ ...response.data.projectData, id });
          if (response.data.projectBeneficiaries.length)
            setProjectBeneficiaries(response.data.projectBeneficiaries);
          else {
            setAlertType("warning");
            setAlertMessage("No project beneficiaries found.");
          }
        } else {
          setAlertType("danger");
          setAlertMessage("Project details could not be retrieved.");
        }
      } catch (error) {
        setAlertType("danger");
        setAlertMessage("Project details could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchProjectBeneficiaries();
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "status" && value === "Active") {
  //     const formattedDateOfBirth = dateOfBirth
  //       ? dateOfBirth
  //         .split("-")
  //         .reverse()
  //         .map((d) => d.substr(-2))
  //         .join("")
  //       : "";

  //     setMemberData((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //       password: formattedDateOfBirth
  //     }));
  //   } else {
  //     setMemberData((prevData) => ({ ...prevData, [name]: value }));
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { id, ...updatedProjectData } = projectData;
      const response = await axios.put(`${server.apiUrl}/editProject`, {
        id,
        projectData: updatedProjectData,
      });
      if (response.data.success) {
        navigate("/projects");
      } else {
        setAlertType("error");
        setAlertMessage("Project could not be updated.");
      }
    } catch (error) {
      setAlertType("error");
      setAlertMessage("Project could not be updated.");
    }
  };

  const handleBack = () => {
    if (userData && userData.role === "Admin"){
      navigate("/projects");
    }
    else if (userData && (userData.role === "Official" || userData.role === "Coordinator")){
      navigate("/");    
    }
  };

  // const handleEditBeneficiary = (beneficiary) => {
  //   let editBeneficiaryUrl = `/beneficiaries/edit?id=${beneficiary.formId}`;
  //   navigate(editBeneficiaryUrl);
  // };

  const handleOpenModal = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setShowModal(true);
  };

  // const handleDeleteBeneficiary = (beneficiary) => {
  //   setBeneficiaries((prevBeneficiaries) =>
  //     prevBeneficiaries.filter((ben) => ben.formId !== beneficiary.formId)
  //   );
  // };

  return (
    <div>
      {userData && (userData.role === "Admin" || userData.role === "Official" || userData.role === "Coordinator") ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
              <>
                <h2>{name}&ensp;
                  {status === "Not Started" ? (
                    <span className="badge text-bg-secondary"><i className="bi bi-circle"></i> {status}</span>
                  ) : status === "In Progress" ? (
                    <span className="badge text-bg-primary"><i className="bi bi-arrow-clockwise"></i> {status}</span>
                  ) : (
                    <span className="badge text-bg-success"><i className="bi bi-check"></i> {status}</span>
                  )}
                  <small className="text-muted ms-3">
                    Start: {startDate} | End: {endDate}
                  </small>
                </h2>
                <button
                  type="button"
                  className="btn btn-outline-danger mt-2"
                  onClick={handleBack}
                >
                  <i className="bi bi-arrow-left me-2"></i>Back
                </button>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="type" className="form-label">
                          Type
                        </label>
                        <input
                          type="text"
                          className="form-control bg-light"
                          id="type"
                          name="type"
                          value={type}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="manager" className="form-label">
                          Manager
                        </label>
                        <input
                          type="text"
                          className="form-control bg-light"
                          id="manager"
                          name="manager"
                          value={manager}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="budget" className="form-label">
                          Budget (Rs.)
                        </label>
                        <input
                          type="text"
                          className="form-control bg-light"
                          id="budget"
                          name="budget"
                          value={budget}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="notes" className="form-label">
                          Notes
                        </label>
                        <textarea
                          className="form-control bg-light"
                          rows="2"
                          id="notes"
                          name="notes"
                          value={notes}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </form>
                {alertMessage && <Alert type={alertType} message={alertMessage} />}
              </>
              <>
            
            {projectBeneficiaries.length > 0 && (
              <>
                <hr />
                <h3 className="mt-4">Project Beneficiaries&ensp;
                  <span className="badge text-bg-dark"><i className="bi bi-person"></i> {projectBeneficiaries.length}</span>
                </h3>
                <table className="table table-striped table-hover mt-4">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>ID</th>
                      <th>Name</th>
                      <th className="text-center">Redeemed</th>
                      {userData && userData.role === "Admin" && (<th>Coordinator</th>)}
                      <th>Notes</th>
                      {userData && userData.role === "Admin" && (<th></th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {projectBeneficiaries.map((projectBeneficiary, index) => (
                      <tr key={projectBeneficiary.beneficiaryData.formId}>
                        <td>{index + 1}</td>
                        <td>{projectBeneficiary.beneficiaryData.id}</td>
                        <td>{projectBeneficiary.beneficiaryData.name}</td>
                        <td className="text-center">
                          {projectBeneficiary.isRedeemed === true ? (
                            <button className="btn btn-sm btn-success">
                              <i className="bi bi-check"></i>
                            </button>
                          ) : (
                            <button className="btn btn-sm btn-danger">
                              <i className="bi bi-x"></i>
                            </button>
                          )}
                        </td>
                        {userData && userData.role === "Admin" && (<td>{projectBeneficiary.beneficiaryData.coordinatorName}</td>)}
                        <td>{projectBeneficiary.notes}</td>
                        {userData && userData.role === "Admin" && (
                          <td>
                            {/*<button
                              className="btn btn-sm btn-outline-success me-2"
                              onClick={() => handleEditBeneficiary(beneficiary)}
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                            {userData && userData.role === "Admin" && (
                              <DeleteBeneficiary
                                beneficiary={beneficiary}
                                onDelete={handleDeleteBeneficiary}
                              />
                            )}*/}
                          </td>
                        )}
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <ProjectBeneficiaries beneficiary={selectedBeneficiaryId} />
                </div> */}
                {showModal && (
                  <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    {/* <div className="modal-dialog" role="document">
                      <div className="modal-content"> */}
                    {/* Modal content */}
                    <ProjectBeneficiaries beneficiary={selectedBeneficiary} />
                  </div>
                  //   </div>
                  // </div>
                )}
              </>
            )}
              
            {/* )} */}
          </>
              </>
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default ProjectBeneficiaries;