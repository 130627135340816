import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "./Spinner";
import Alert from "./Alert";
import MaskedInput from "react-text-mask";
import server from '../config/server';

const MembershipForm = () => {
  const initialState = {
    id: "",
    name: "",
    email: "",
    password: "",
    contactNo: "",
    address: "",
    dateOfBirth: "",
    gender: "",
    education: "",
    occupation: "",
    joiningDate: new Date().toISOString().split("T")[0],
    role: "",
    pastExperience: "",
    coreCompetence: "",
    notes: "",
    status: "Inactive"
  };

  const [memberData, setMemberData] = useState(initialState);

  const {
    name,
    email,
    contactNo,
    address,
    dateOfBirth,
    gender,
    education,
    occupation,
    pastExperience,
    coreCompetence,
    notes,
  } = memberData;

  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMemberData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const generateMemberId = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server.apiUrl}/getMemberCount`);
        const totalCount = response.data.memberCount;
        const newId = `M${String(totalCount + 1).padStart(5, '0')}`;
        setMemberData((prevData) => ({
          ...prevData,
          id: newId,
        }));
      } catch (error) {
        setAlertType("danger");
        setAlertMessage(`${error.message}`);
      }
      setIsLoading(false);
    };
    generateMemberId();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${server.apiUrl}/addMember`, {
        memberData
      });

      if (response.status === 201) {
        setAlertType("success");
        setAlertMessage(response.data.message);
        setMemberData(initialState);
      } else {
        setAlertType("danger");
        setAlertMessage(`Failed to add member: ${response.data.error}`);
      }
    } catch (error) {
      setAlertType("danger");
      setAlertMessage(`Failed to add member: ${error.message}`);
    }
    setIsLoading(false);
  };

  const handleClear = () => {
    setMemberData(initialState);
  };

  return (
    <div className="bg-dark min-vh-100">
      <div className="container pt-2 pb-4 px-4 col-md-12 border border-secondary border-1 rounded bg-light">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  src="/logos/logo-login.png"
                  //className="img-fluid mx-auto d-block"
                  style={{
                    width: "300px",
                  }}
                  alt="Rearing Youth"
                />
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-end">
                <h1>MEMBERSHIP FORM</h1>
              </div>
              <hr />
            </div>
            <form onSubmit={handleSubmit} className="mt-4">
              <div className="row mb-3">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="contactNo" className="form-label">
                      Contact No.<span className="text-danger">*</span>
                    </label>
                    <MaskedInput
                      mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                      className="form-control"
                      id="contactNo"
                      name="contactNo"
                      value={contactNo}
                      onChange={handleChange}
                      placeholder="0000-0000000"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-8">
                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      Address<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      name="address"
                      value={address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="dateOfBirth" className="form-label">
                      Date of Birth<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      value={dateOfBirth}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="education" className="form-label">
                      Education<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="education"
                      name="education"
                      value={education}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="occupation" className="form-label">
                      Occupation<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="occupation"
                      name="occupation"
                      value={occupation}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label">
                      Gender<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      id="gender"
                      name="gender"
                      value={gender}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        Select Gender
                      </option>
                      <option key="Male" value="Male">
                        Male
                      </option>
                      <option key="Female" value="Female">
                        Female
                      </option>
                      <option key="Other" value="Other">
                        Other
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="coreCompetence" className="form-label">
                      Areas of Expertise<span className="text-danger">*</span>
                      <small className="ms-1 text-muted fst-italic">
                        (Briefly describe your primary skills, expertise, or areas of knowledge. E.g., Teaching, Project Management, Leadership, IT, etc.)
                      </small>
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      id="coreCompetence"
                      name="coreCompetence"
                      value={coreCompetence}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="pastExperience" className="form-label">
                      Past Volunteering Experience<span className="text-danger">*</span>
                      <small className="ms-1 text-muted fst-italic">
                        (Mention your previous volunteering engagements, including roles, organizations involved, and the duration of your service.)
                      </small>
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      id="pastExperience"
                      name="pastExperience"
                      value={pastExperience}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="notes" className="form-label">
                      Notes
                      <small className="ms-1 text-muted fst-italic">
                        (Feel free to include any additional information or comments you think would be helpful for us to know about you. This could include specific interests, availability, or reasons for wanting to join our organization.)
                      </small>
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      id="notes"
                      name="notes"
                      value={notes}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn btn-outline-success me-2"
                  >
                    <i className="bi bi-send"></i> Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={handleClear}
                  >
                    <i className="bi bi-eraser-fill"></i> Clear
                  </button>
                </div>
              </div>
            </form>
            {alertMessage && <Alert type={alertType} message={alertMessage} />}
          </>
        )}
      </div>
    </div>
  );
};

export default MembershipForm;