import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const DonationsByPurposeChart = ({ donations }) => {
    // Convert object to an array if needed
    const dataArray = Array.isArray(donations)
    ? donations
    : Object.values(donations); // Converts object to an array
  
    if (!Array.isArray(dataArray) || dataArray.length === 0) {
        return <p>No data available</p>;
    }
    
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    
    const RADIAN = Math.PI / 180;

    // Calculate total donations
    const totalDonations = dataArray.reduce((sum, donor) => sum + donor.totalAmount, 0);
    
    const renderCustomizedLabel = ({ cx, cy, midAngle, outerRadius, index }) => {
      const radius = outerRadius * 1.2; // Position labels slightly outside the pie
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
      return (
        <text
            x={x}
            y={y}
            fill="black"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
            fontSize={12} // Reduce font size slightly for better fitting
        >
            <tspan x={x} dy="-0.5em" fontWeight="bold">{dataArray[index].purpose}</tspan>
            <tspan x={x} dy="1.2em">Rs. {dataArray[index].totalAmount.toLocaleString()}</tspan>
        </text>
      );
    };
  
    return (
      <div className="card text-center mt-5">
                  <div className="card-body">
                    <h3 className="text-start p-1 mt-2 mb-2">Donations Breakdown by Purpose</h3>
                    {/* <table className="table table-sm table-striped table-hover mt-3">
                      <thead>
                        <tr>
                          <th scope="col">Donor ID</th>
                          <th scope="col">Donor Name</th>
                          <th scope="col">Total Donations (Rs)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(donationSummary).map(([id, donor]) => (
                          <tr key={id}>
                            <td>{id}</td>
                            <td>{donor.name}</td>
                            <td>{parseFloat(donor.totalDonations).toLocaleString()}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="2">Total Donations</th>
                          <th>{totalDonations.toLocaleString()}</th>
                        </tr>
                      </tfoot>
                    </table> */}
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie
                          data={dataArray}
                              cx="50%"
                              cy="50%"
                              outerRadius="75%"
                              fill="#8884d8"
                              dataKey="totalAmount"
                              label={renderCustomizedLabel} // Use the custom label function
                              labelLine={true} // Draw a line to the labels
                              // labelLine={false}
                              // margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          {dataArray.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="card-footer text-body-secondary">
                    <strong>Total Donations: </strong>
                    <span class="badge rounded-pill text-bg-primary">Rs. {totalDonations.toLocaleString()}</span>
                  </div>
                </div>
      
    );
  };

export default DonationsByPurposeChart;