import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import { Link } from "react-router-dom";
import axios from "axios";
import Spinner from "./Spinner";
import Alert from "./Alert";
import { useUser } from '../context/UserContext';
import { useNavigate } from "react-router-dom";
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';
//import DeleteBeneficiary from "./DeleteBeneficiary";
import Error from "./Error";


const UnassignedBeneficiaries = () => {
  useRequireAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);


  useEffect(() => {
    // Close modal immediately on component mount
    const closeModal = () => document.querySelector('[data-bs-dismiss="modal"]')?.click();

    // Combine fetch logic into one async function
    const fetchData = async () => {
      setIsLoading(true);  // Set loading true at the start

      try {
        // Use Promise.all to fetch both resources concurrently
        const [beneficiariesResponse, projectsResponse] = await Promise.all([
          axios.post(`${server.apiUrl}/getUnassignedBeneficiaries`, {
            userId: userData.id,
            role: userData.role
          }),
          axios.get(`${server.apiUrl}/getOpenProjects`)
        ]);

        // Handle Beneficiaries Data
        if (beneficiariesResponse.data.success) {
          if (beneficiariesResponse.data.userBeneficiaries.length) {
            setBeneficiaries(beneficiariesResponse.data.userBeneficiaries);
          } else {
            setAlertType("warning");
            setAlertMessage("No beneficiaries found.");
          }
        } else {
          setAlertType("danger");
          setAlertMessage("Beneficiaries could not be retrieved.");
        }

        // Handle Projects Data
        if (projectsResponse.data.success) {
          if (projectsResponse.data.projects.length) {
            setProjects(projectsResponse.data.projects);
          } else {
            setAlertType("warning");
            setAlertMessage("No projects found.");
          }
        } else {
          setAlertType("danger");
          setAlertMessage("Projects could not be retrieved.");
        }

      } catch (error) {
        setAlertType("danger");
        setAlertMessage("An error occurred while fetching data.");
      } finally {
        setIsLoading(false);  // Set loading to false after both fetches are complete
      }
    };

    closeModal();  // Close modal on mount
    fetchData();   // Fetch both beneficiaries and projects
  }, [userData]);

  const handleEditBeneficiary = (beneficiary) => {
    let editBeneficiaryUrl = `/beneficiaries/edit?id=${beneficiary.formId}`;
    navigate(editBeneficiaryUrl);
  };

  // const handleDeleteBeneficiary = (beneficiary) => {
  //   setBeneficiaries((prevBeneficiaries) =>
  //     prevBeneficiaries.filter((ben) => ben.formId !== beneficiary.formId)
  //   );
  // };

  // ----------------------------------------------------------------------------------

  const initialState = {
    projectId: "",
    isRedeemed: false,
    notes: "",
  };

  const [projectData, setProjectData] = useState(initialState);
  const [formReset, setFormReset] = useState(false);
  const [modalAlertType, setModalAlertType] = useState(null);
  const [modalAlertMessage, setModalAlertMessage] = useState("");
  const { projectId, isRedeemed, notes } = projectData;
  const [projects, setProjects] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    document.querySelector('[data-bs-dismiss="modal"]')?.click();
    setIsLoading(true);
    try {
      const response = await axios.post(`${server.apiUrl}/addProjectBeneficiary`, {
        projectData,
        beneficiaryId: selectedBeneficiary,
      });

      if (response.status === 201) {
        setModalAlertType("success");
        setModalAlertMessage(response.data.message);
        setProjectData(initialState);
        setFormReset(!formReset);
      } else {
        setModalAlertType("danger");
        setModalAlertMessage(`Failed to add beneficiary to the project: ${response.data.error}`);
      }
    } catch (error) {
      setModalAlertType("danger");
      setModalAlertMessage(`Failed to add beneficiary to the project: ${error.message}`);
    }
    setIsLoading(false);
  };

  return (
    <div>
      {userData && userData.role === "Admin" ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <h2>Unassigned Beneficiaries</h2>
                <Link
                  to={`/beneficiaries/add`}
                  className="btn btn-outline-secondary mt-3"
                >
                  Add Beneficiary
                </Link>
                {alertMessage ? (
                  <Alert type={alertType} message={alertMessage} />
                ) : (
                  <>
                  {modalAlertMessage && (
                  <Alert type={modalAlertType} message={modalAlertMessage} />)}
                    <table  id="example" className="table table-striped table-hover mt-4">
                      <thead>
                        <tr>
                          <th></th>
                          <th>ID</th>
                          <th>CNIC</th>
                          <th>Name</th>
                          <th className="text-center">Verified</th>
                          {userData && userData.role !== "Admin" && (
                            <th>Contact No.</th>
                          )}
                          <th>Occupation</th>
                          {userData && userData.role === "Admin" && (
                            <>
                              <th>Created By</th>
                              <th>Coordinator</th>
                            </>
                          )}
                          <th>Notes</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {beneficiaries.map((beneficiary) => (
                          <tr key={beneficiary.formId}>
                            <td>
                              <button type="button" className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setSelectedBeneficiary(beneficiary.id)}>
                                <i class="bi bi-person-plus"></i>
                              </button>
                            </td>
                            <td>{beneficiary.id}</td>
                            <td>{beneficiary.cnic}</td>
                            <td>{beneficiary.name}</td>
                            <td className="text-center">
                              {beneficiary.isVerified === "true" ? (
                                <button className="btn btn-sm btn-success">
                                  <i className="bi bi-check"></i>
                                </button>
                              ) : (
                                <button className="btn btn-sm btn-danger">
                                  <i className="bi bi-x"></i>
                                </button>
                              )}
                            </td>
                            {userData && userData.role !== "Admin" && (
                              <td>{beneficiary.contactNo}</td>
                            )}
                            <td>{beneficiary.occupation}</td>
                            {userData && userData.role === "Admin" && (
                              <>
                                <td>{beneficiary.createdByName}</td>
                                <td>{beneficiary.coordinatorName}</td>
                              </>
                            )}
                            <td>{beneficiary.notes}</td>
                            <td>
                              <button
                                className="btn btn-sm btn-outline-success me-2"
                                onClick={() => handleEditBeneficiary(beneficiary)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                              {/* {userData && userData.role === "Admin" && (
                                <DeleteBeneficiary
                                  beneficiary={beneficiary}
                                  onDelete={handleDeleteBeneficiary}
                                />
                              )} */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>         
                      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h1 class="modal-title fs-5" id="staticBackdropLabel">Add Beneficiary to Project</h1>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              {selectedBeneficiary ? (
                                <>
                                  <div className="container mt-1">
                                    {isLoading ? (
                                      <Spinner />
                                    ) : (
                                      <>
                                        <form id="projectBeneficiaryForm" onSubmit={handleSubmit}>
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="mb-3">
                                                <label htmlFor="projectId" className="form-label">
                                                  Project
                                                </label>
                                                <select
                                                  className="form-select"
                                                  id="projectId"
                                                  name="projectId"
                                                  value={projectId}
                                                  onChange={handleChange}
                                                  required
                                                >
                                                  <option value="">
                                                    Select Project
                                                  </option>
                                                  {projects.map(project => (
                                                    <option key={project.id} value={project.id}>
                                                      {project.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="mb-3">
                                                <label htmlFor="notes" className="form-label">
                                                  Notes
                                                </label>
                                                <textarea
                                                  className="form-control"
                                                  rows="4"
                                                  id="notes"
                                                  name="notes"
                                                  value={notes}
                                                  onChange={handleChange}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                        {alertMessage && <Alert type={alertType} message={alertMessage} />}
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <Error />
                              )}
                            </div>
                            <div class="modal-footer">
                              <button type="button" className="btn btn-outline-danger me-2" data-bs-dismiss="modal">
                                <i className="bi bi-x-square me-2"></i>Cancel
                              </button>
                              <button type="submit" form="projectBeneficiaryForm" className="btn btn-outline-success me-2">
                                <i className="bi bi-save me-2"></i>Add
                              </button>
                            </div>
                          </div>
                        </div>  
                      </div>
                  </>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default UnassignedBeneficiaries;