import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import Alert from "./Alert";
import Spinner from "./Spinner";
import Error from "./Error";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const Notifications = () => {
  useRequireAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${server.apiUrl}/getAllNotifications`, {
          userRole: userData.role
        });

        if (response.data.success) {
          if (response.data.notifications.length)
            setNotifications(response.data.notifications);
          else {
            setAlertType("warning");
            setAlertMessage("No notifications found.");
          }
        } else {
          setAlertType("danger");
          setAlertMessage("Notifications could not be retrieved.");
        }
      } catch (error) {
        setAlertType("danger");
        setAlertMessage("Notifications could not be retrieved.");
      }
      setIsLoading(false);
    };

    fetchNotifications();
  }, []);

  const handleEditNotification = (notification) => {
    let editNotificationUrl = `/notifications/edit?id=${notification.formId}`;
    navigate(editNotificationUrl);
  };

  // const handleViewProjectBeneficiaries = (project) => {
  //   let viewProjectBeneficiariesUrl = `/projectbeneficiaries?id=${project.id}`;
  //   navigate(viewProjectBeneficiariesUrl);
  // };

  return (
    <div>
      {userData && userData.role === "Admin" ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <h2>Notifications</h2>
                <Link
                  to={`/notifications/add`}
                  className="btn btn-outline-secondary mt-3"
                >
                  Add Notification
                </Link>
                {alertMessage ? (
                  <Alert type={alertType} message={alertMessage} />
                ) : (
                  <table className="table table-striped table-hover mt-4">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Type</th>
                        <th>Target Groups</th>
                        <th>Publish Date</th>
                        <th>Expiry Date</th>
                        <th>Description</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {notifications.map((notification) => (
                        <tr key={notification.formId}>
                          <td>{notification.id}</td>
                          <td>{notification.title}</td>
                          <td>{notification.type}</td>
                          <td>{Array.isArray(notification.targetGroups) ? notification.targetGroups.join(", ") : notification.targetGroups}</td>
                          <td>{notification.publishDate}</td>
                          <td>{notification.expiryDate}</td>
                          <td>{notification.description}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-outline-success me-2"
                              onClick={() => handleEditNotification(notification)}
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                            {/*<button
                              className="btn btn-sm btn-outline-primary me-2"
                              onClick={() => handleViewProjectBeneficiaries(project)}
                            >
                              <i className="bi bi-list-ul"></i>
                            </button> */}
                            {/* <RemoveTransaction
                      transactionType={transactionType}
                      transaction={transaction}
                      onRemove={handleRemovemember}
                    /> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default Notifications;