import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";
import Alert from "./Alert";
//import Graph from "./Graph";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';
import ActiveProjectBeneficiariesChart from './ActiveProjectBeneficiariesChart';
import DonationsByPurposeChart from "./DonationsByPurposeChart";
import DonationsByDonorsChart from "./DonationsByDonorsChart";

const Home = () => {
  useRequireAuth();
  const navigate = useNavigate();
  const { userData } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  //const [dateTime, setDateTime] = useState(null);
  const [activeProjectBeneficiaries, setActiveProjectBeneficiaries] = useState({});
  const [currentYearDonationsByPurpose, setCurrentYearDonationsByPurpose] = useState({});
  const [currentYearDonationsByDonor, setCurrentYearDonationsByDonor] = useState({});
  // const [donationSummary, setDonationSummary] = useState({});
  // const [totalDonations, setTotalDonations] = useState(0);
  const [lastThreeDonations, setLastThreeDonations] = useState([]);
  //const [totalBeneficiaries, setTotalBeneficiaries] = useState(0);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [ongoingProjects, setOngoingProjects] = useState([]);
  const [currentNotifications, setCurrentNotifications] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!userData) return;
  
      setIsLoading(true);
      try {
        const isAdmin = userData.role === "Admin";
        const requests = isAdmin
          ? [
              axios.post(`${server.apiUrl}/getActiveProjectBeneficiaries`, { userRole: userData.role }),
              axios.post(`${server.apiUrl}/getCurrentYearDonationsByPurpose`, { userRole: userData.role }),
              axios.post(`${server.apiUrl}/getCurrentYearDonationsByDonor`, { userRole: userData.role }),
            ]
          : [
              //axios.post(`${server.apiUrl}/getGroupedDonations`, { userId: userData.id, userRole: userData.role }),
              axios.get(`${server.apiUrl}/getLastThreeDonations`),
              axios.post(`${server.apiUrl}/getCoordinatorBeneficiaryCountsForOpenProjects`, { userId: userData.id, userRole: userData.role }),
              axios.post(`${server.apiUrl}/getAllNotifications`, { userRole: userData.role })
            ];
  
        const responses = await Promise.all(requests);
  
        const responseHandlers = isAdmin
          ? [
              (data) => setActiveProjectBeneficiaries(data.activeProjectBeneficiaries),
              (data) => setCurrentYearDonationsByPurpose(data.donations),
              (data) => setCurrentYearDonationsByDonor(data.donations),
            ]
          : [
              // (data) => {
              //   setDonationSummary(data.donationSummary);
              //   setTotalDonations(Object.values(data.donationSummary).reduce((acc, donor) => acc + parseFloat(donor.totalDonations), 0));
              // },
              (data) => setLastThreeDonations(data.userDonations),
              (data) => setOngoingProjects(data.projects),
              (data) => setCurrentNotifications(data.notifications)
            ];
  
        responses.forEach((response, index) => {
          if (response.data.success) {
            responseHandlers[index](response.data);
          } else {
            throw new Error("Failed to fetch some data.");
          }
        });
  
      } catch (error) {
        setAlertType("danger");
        setAlertMessage(`Failed to fetch data: ${error.message}`);
      } finally {
        //setDateTime(new Date().toString());
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [userData]);
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     try {
        // const [activeProjectBeneficiariesResponse, currentYearDonationsByPurposeResponse, currentYearDonationsByDonorResponse, groupedDonationsResponse, lastThreeDonationsResponse, ongoingProjectsResponse, currentNotificationsResponse] = await Promise.all([
        //   axios.post(`${server.apiUrl}/getActiveProjectBeneficiaries`, {
        //     userRole: userData.role
        //   }),
        //   axios.post(`${server.apiUrl}/getCurrentYearDonationsByPurpose`, {
        //     userRole: userData.role
        //   }),
        //   axios.post(`${server.apiUrl}/getCurrentYearDonationsByDonor`, {
        //     userRole: userData.role
        //   }),
        //   axios.post(`${server.apiUrl}/getGroupedDonations`, {
        //     userId: userData.id,
        //     userRole: userData.role
        //   }),
        //   axios.get(`${server.apiUrl}/getLastThreeDonations`),
        //   axios.post(`${server.apiUrl}/getCoordinatorBeneficiaryCountsForOpenProjects`, {
        //     userId: userData.id,
        //     userRole: userData.role
        //   }),
        //   axios.post(`${server.apiUrl}/getAllNotifications`, {
        //     userRole: userData.role
        //   })
        // ]);

        // if (activeProjectBeneficiariesResponse.data.success) {
        //   setActiveProjectBeneficiaries(activeProjectBeneficiariesResponse.data.activeProjectBeneficiaries);
        //   //setTotalBeneficiaries(Object.values(groupedBeneficiariesResponse.data.beneficiarySummary).reduce((acc, coordinator) => acc + parseFloat(coordinator.count), 0));
        // } else {
        //   setAlertType("danger");
        //   setAlertMessage("Project Beneficiaries could not be retrieved.");
        // }

        // if (currentYearDonationsByPurposeResponse.data.success) {
        //   setCurrentYearDonationsByPurpose(currentYearDonationsByPurposeResponse.data.donations);
        // } else {
        //   setAlertType("danger");
        //   setAlertMessage("Donations could not be retrieved.");
        // }

        // if (currentYearDonationsByDonorResponse.data.success) {
        //   setCurrentYearDonationsByDonor(currentYearDonationsByDonorResponse.data.donations);
        // } else {
        //   setAlertType("danger");
        //   setAlertMessage("Donations could not be retrieved.");
        // }

        // if (groupedDonationsResponse.data.success) {
        //   setDonationSummary(groupedDonationsResponse.data.donationSummary);
        //   setTotalDonations(Object.values(groupedDonationsResponse.data.donationSummary).reduce((acc, donor) => acc + parseFloat(donor.totalDonations), 0));
        // } else {
        //   setAlertType("danger");
        //   setAlertMessage("Donations could not be retrieved.");
        // }

        // if (lastThreeDonationsResponse.data.success) {
        //   setLastThreeDonations(lastThreeDonationsResponse.data.userDonations);
        // } else {
        //   setAlertType("danger");
        //   setAlertMessage("Donations could not be retrieved.");
        // }

        // if (ongoingProjectsResponse.data.success) {
        //   setOngoingProjects(ongoingProjectsResponse.data.projects);
        // } else {
        //   setAlertType("danger");
        //   setAlertMessage("Ongoing projects could not be retrieved.");
        // }

        // if (currentNotificationsResponse.data.success) {
        //   setCurrentNotifications(currentNotificationsResponse.data.notifications);
        // } else {
        //   setAlertType("danger");
        //   setAlertMessage("Current notifications could not be retrieved.");
        // }
  //     } catch (error) {
  //       setAlertType("danger");
  //       setAlertMessage(`Failed to fetch data: ${error.message}`);
  //     }
  //     setDateTime(new Date().toString());
  //     setIsLoading(false);
  //   };

  //   fetchData();
  // }, [userData]);  

   // Function to calculate "X days ago"
   const getDaysAgo = (date) => {
    const donationDate = new Date(date);
    const today = new Date();
    const timeDiff = today - donationDate;
    const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (daysAgo === 0) return "Today";
    if (daysAgo === 1) return "1 day ago";
    return `${daysAgo} days ago`;
  };

  // const handleViewProjectBeneficiaries = (project) => {
  //   let viewProjectBeneficiariesUrl = `/projectbeneficiaries?id=${project.id}`;
  //   navigate(viewProjectBeneficiariesUrl);
  // };

  return (
    <div>
      <NavBar />
      <div className="container mt-4">
        {userData && <h2>Hello, {userData.name}!</h2>}
        {isLoading ? (
          <Spinner />
        ) : alertMessage ? (
          <Alert type={alertType} message={alertMessage} />
        ) : userData && userData.role === "Admin" ? (
          <>
            <div className="row">
              <div className="col-md-12">
                <ActiveProjectBeneficiariesChart activeProjectBeneficiaries={activeProjectBeneficiaries} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <DonationsByPurposeChart donations={currentYearDonationsByPurpose} />
              </div>
              <div className="col-md-6">
                <DonationsByDonorsChart donations={currentYearDonationsByDonor} />
              </div>
            </div>
          </>
        ) : (
          <div className="row mt-5">
            <div className="col-md-8">
              {currentNotifications.map((notification) => (
                <div key={notification.id} className="card mb-3">
                  <div className="row g-0">
                    {/* Left Section with SVG */}
                    <div className="col-md-4 d-flex align-items-center justify-content-center bg-light">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="bi bi-megaphone w-75 h-75 p-4"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 75 75 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233q.27.015.537.036c2.568.189 5.093.744 7.463 1.993zm-9 6.215v-4.13a95 95 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A61 61 0 0 1 4 10.065m-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68 68 0 0 0-1.722-.082z" />
                      </svg>
                    </div>

                    {/* Right Section with Notification Details */}
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">{notification.title}</h5>
                        <p className="card-text">{notification.description}</p>
                        <p className="card-text">
                          <small className="text-body-secondary">{notification.publishDate}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-4">
              <h4>Ongoing Projects</h4>
              <hr />
              <div className="list-group">
                {ongoingProjects.length > 0 ? (
                  ongoingProjects.map((project) => (
                    <div 
                      className="card bg-success bg-gradient text-white mb-3 d-flex justify-content-between align-items-center flex-row p-3" 
                      style={{ cursor: "pointer" }} 
                      onClick={() => navigate(`/projectbeneficiaries?id=${project.projectData.id}`)}
                    >
                      <div>
                        <h5 className="card-title">{project.projectData.name}</h5>
                        <p className="card-text">
                          Your beneficiary count: <span className="fw-bold">{project.beneficiaryCount.toLocaleString()}</span>
                        </p>
                      </div>
                      <i className="bi bi-arrow-right-circle fs-4"></i>
                    </div>
                  ))
                ) : (
                  <div className="alert alert-warning fw-bold" role="alert">
                    There are no ongoing projects. <i className="bi bi-emoji-frown"></i>
                  </div>
                )}
              </div>
              <h4 className="mt-5">Recent Donations</h4>
              <hr/>
              <div class="list-group">
              {lastThreeDonations.length > 0 ? (
                lastThreeDonations.map((donation, index) => (
                  <a href="#" class={`list-group-item list-group-item-action bg-secondary bg-gradient text-white
                    ${
                      index === 0 ? "bg-opacity-100" : index === 1 ? "bg-opacity-75" : "bg-opacity-50"
                    }`}>
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">Rs {parseFloat(donation.amount).toLocaleString()}
                      </h5>
                      <small>{getDaysAgo(donation.paymentDate)}</small>
                    </div>
                    <p class="mb-1">for {donation.purpose}</p>
                    {/* <small class="text-body-secondary">And some muted small print.</small> */}
                  </a>
                ))
              ) : (
                <div className="alert alert-warning fw-bold" role="alert">No donations found. <i class="bi bi-emoji-frown"></i></div>
              )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;