import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import Alert from "./Alert";
import Error from "./Error";
import { useUser } from '../context/UserContext';
import useRequireAuth from '../hooks/useRequireAuth';
import server from '../config/server';

const AddNotification = () => {
  useRequireAuth();
  const { userData } = useUser();

  const initialState = {
    id: "",
    title: "",
    type: "",
    targetGroups: [],
    publishDate: "",
    expiryDate: "",
    description: "",
  };

  const [notificationData, setNotificationData] = useState(initialState);
  const [formReset, setFormReset] = useState(false);

  const { title, type, targetGroups, publishDate, expiryDate, description } = notificationData;

  const navigate = useNavigate();

  // State variables to manage the alert
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const generateNotificationId = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server.apiUrl}/getNotificationCount`);
        const totalCount = response.data.notificationCount;
        const newId = `N${String(totalCount + 1).padStart(5, '0')}`;
        setNotificationData((prevData) => ({
          ...prevData,
          id: newId,
        }));
      } catch (error) {
        console.error("Error fetching total count of notifications:", error);
      }
      setIsLoading(false);
    };
    generateNotificationId();
  }, [formReset]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${server.apiUrl}/addNotification`, {
        notificationData
      });

      if (response.status === 201) {
        setAlertType("success");
        setAlertMessage(response.data.message);
        setNotificationData(initialState);
        setFormReset(!formReset);
      } else {
        setAlertType("danger");
        setAlertMessage(`Failed to add notification: ${response.data.error}`);
      }
    } catch (error) {
      setAlertType("danger");
      setAlertMessage(`Failed to add notification: ${error.message}`);
    }
    setIsLoading(false);
  };

  const handleBack = () => {
    navigate(`/notifications`);
  };

  // Handles changes for both input fields and checkboxes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setNotificationData((prevData) => ({
        ...prevData,
        targetGroups: checked
          ? [...prevData.targetGroups, value] // Add value if checked
          : prevData.targetGroups.filter((item) => item !== value), // Remove if unchecked
      }));
    } else {
      setNotificationData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  return (
    <div>
      {userData && userData.role === "Admin" ? (
        <>
          <NavBar />
          <div className="container mt-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <h2>
                  Add Notification
                </h2>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="mb-3">
                        <label htmlFor="title" className="form-label">
                          Title <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          name="title"
                          value={title}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>             
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="type" className="form-label">
                          Type <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          id="type"
                          name="type"
                          value={type}
                          onChange={handleChange}
                          required
                        >
                          <option value="">
                            Select Type
                          </option>
                          <option key="Announcement" value="Announcement">
                            Announcement
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Target Groups</label>
                        <div className="d-flex gap-3">
                          {[
                            { id: "Official", label: "Official" },
                            { id: "Coordinator", label: "Coordinator" },
                            { id: "Patron", label: "Patron" },
                          ].map((group) => (
                            <div key={group.id} className="form-check d-inline-block">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`targetGroups-${group.id}`}
                                value={group.id}
                                checked={targetGroups.includes(group.id)}
                                onChange={handleChange}
                              />
                              <label className="form-check-label ms-1" htmlFor={`targetGroups-${group.id}`}>
                                {group.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>                       
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="publishDate" className="form-label">
                          Publish Date <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="publishDate"
                          name="publishDate"
                          value={publishDate}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="expiryDate" className="form-label">
                          Expiry Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="expiryDate"
                          name="expiryDate"
                          value={expiryDate}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          rows="4"
                          id="description"
                          name="description"
                          value={description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-outline-success me-2"
                      >
                        <i className="bi bi-save me-2"></i>Add
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={handleBack}
                      >
                        <i className="bi bi-x-square me-2"></i>Back
                      </button>
                    </div>
                  </div>
                </form>
                {alertMessage && <Alert type={alertType} message={alertMessage} />}
              </>
            )
            }
          </div>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default AddNotification;